import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Button, Form, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { toast } from 'react-toastify'
import LoadingPage from './LoadingPage'

// const API_URL = process.env.REACT_APP_BASE_URL
const API_URL = "https://rcorintelliger.co:8021"

function Login() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [islogin, setIsLogin] = useState(false)

    const userLogin = async (userData)=>{
        console.log(userData);
        
        try {
            const response = await axios.post(`${API_URL}/Analytics/auth`, userData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    // 'Authority': hostName,
                    withCredentials: true,
                },
                mode: 'cors',
            })
          console.log(response);
          if(response){
            localStorage.setItem("rcor_analytics_url", response?.data?.redirecturl)
            localStorage.setItem("rcor_analytics_user", JSON.stringify(response?.data?.user))
            // setIsLogin(false)
            window.location.href = response?.data?.redirecturl
          }
        }
        catch (error) {
            console.log(error);           
            setIsLogin(false)
            if(error?.response?.data?.message){
                toast.error(error.response.data.message, {
                    autoClose: 5000
                })
            }else{               
            toast.error(error.message, {
                autoClose: 5000
            })
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLogin(true)
        let userData = {
            "Username": username,
            "Password": password,
        }
        userLogin(userData)
    }

    return (
        <>{!islogin?
            <div className='d-flex align-items-center justify-content-center vh-100 login bg-image'>
                <Row >
                    <Col xs={12}>
                        {/* Creating a Card */}
                        <Card className='card'>

                            <Card.Body className='card-body'>

                                <Card.Title className='text-center' style={{ color: '#489C8F' }}>Sign in</Card.Title>
                                <Card.Text className='mt-3 text-center'>
                                    To Access RCOR Analytics Admin
                                </Card.Text>
                                <Form onSubmit={handleSubmit}>
                                    {/* Adding form controls with headings */}
                                    <Form.Group controlId='formBasicEmail'>
                                        <Form.Label className='text-left'>Username</Form.Label>
                                        <Form.Control required className='textfield' type='text' value={username} onChange={(e) => setUsername(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId='formBasicPassword'>
                                        <Form.Label className=' mt-3 text-left'>Password</Form.Label>
                                        <Form.Control required className='textfield' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </Form.Group>

                                    {/* <Form.Group className="mt-4 d-flex align-items-center">
                                        <Form.Check type='checkbox' id='rememberPassword' className='me-2' />
                                        <Form.Label className='mb-0 me-3 '  >Remember Me</Form.Label>
                                    </Form.Group> */}

                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            {!islogin && <Button type='submit' className='login login-button mt-4 mb-4'>LOGIN</Button>}
                                            {islogin &&
                                                <Button className='button button-disable w-100 d-flex justify-content-center align-items-center mt-4 mb-4' >
                                                    <span className='me-2'>Signing</span>
                                                    <Spinner animation="border" variant="light" size='sm' />
                                                </Button>}
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
        </div>:
        <LoadingPage/>
        }</>
    )
}

export default Login