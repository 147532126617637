import { useEffect } from 'react';

const Logout = () => {

    const url = localStorage.getItem("rcor_analytics_url")
    const user = localStorage.getItem("rcor_analytics_user")

  useEffect(() => {
    // Clear local storage data
    // if(url && user){
        localStorage.removeItem("rcor_analytics_url");
        localStorage.removeItem("rcor_analytics_user");
    
        // Redirect to the logout page
        // navigate('/logout');
    // }  
    window.location.href = '/login'

  }, [url, user]);

  return null; // Return nothing to render
};

export default Logout;
