import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './pages/Login';
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import { ToastContainer } from 'react-toastify';
import Logout from './pages/Logout';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<Logout/>} />
        </Routes>
      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={5000}
      />
    </div>
  );
}

export default App;
