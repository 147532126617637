import React from 'react'
import { Col, Row } from 'react-bootstrap'

function LoadingPage() {

    return (
        <div className='d-flex flex-column align-items-center justify-content-center vh-100 login bg-image'>
            {/* Centering the content vertically and horizontally */}
            <Row >
                <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                    <img src={process.env.PUBLIC_URL + `/assets/images/rcorLogo.png`} alt='logo' width={121} height={45} />
                    {/* <span className='mt-3'>Welcome, {user?.firstname}</span> */}
                    <span className='mt-3'>Please wait. We are loading your data</span>
                    <img className='mt-3 flip-animation' src={process.env.PUBLIC_URL + '/assets/images/rcorlogo_flip.png'} width={114} height={100} />
                </Col>
            </Row>
        </div>

    )
}

export default LoadingPage